import React, { useState, useEffect } from 'react';
import { auth } from "../firebase";

const Dashboard = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
        setUser(user);
      } else {
        setLoggedIn(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="dashboard">
      {loggedIn ? (
        <div className="box">
          <header>
            <h2>Welcome, {user.displayName || user.email}!</h2>
          </header>
          <div classname="">
            <a href="/add-bulletin">Add Bulletin</a>
          </div>
          <button onClick={() => auth.signOut()}>Logout</button>
        </div>
      ) : (
        <h1>Please log in to access the dashboard.</h1>
      )}
    </div>
  );
};

export default Dashboard;
