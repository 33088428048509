import { collection, getDocs, query, where } from "firebase/firestore";
import {db} from '../firebase';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useParams } from "react-router-dom";

const Category = () => {
    const { slug } = useParams();
    console.log("slug: " + slug);
	const [cat, setCat] = useState([]);
	const [bulletins, setBulletins] = useState([]);
   
    const fetchPost = async () => {
        const q = query(collection(db, "categories"), where("slug", "==", slug));
        const querySnap = await getDocs(q);
        let categoryId = null;
        querySnap.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          categoryId = doc.id;
          setCat(doc.data());
          console.log(doc.id, " => ", doc.data());
        });
        
        await getDocs(collection(db, "bulletins")).then((querySnapshot) => {
            const newData = querySnapshot.docs
              .filter((doc) => {
                const bulletinCategories = doc.data().categories || [];
                return bulletinCategories.includes(categoryId);
              })
              .map((doc) => ({ ...doc.data(), id: doc.id }));
          
            var newestData = _.orderBy(newData, ["createdAt"], ["desc"]); // Change 'title' to 'createdAt' and add 'desc' for descending order
            setBulletins(newestData);
            console.log(bulletins, newData);
          });
    };
    const CategoryName = (cat) => {
        
        if (cat) {
            return (
                <h2>{cat}</h2>
            )
            }
    }
    const BulletinList = (bulletins) => {
        if(bulletins.length > 0){
            return bulletins.map((bulletin) =>
            <a key={bulletin.id} href={"/bulletins/" + bulletin.slug}>
            <li key={bulletin.id} className="list-group-item list-group-item-action">
            <span className="d-flex justify-content-between align-items-center"><h3>{bulletin.title} </h3>
         </span><br/>{bulletin.description}<br/><br/>
        </li></a>
        )
        } else {
            return (
              <div className="d-flex justify-content-center align-items-center">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div>
            )
        }
      }
    
        useEffect(()=>{
            fetchPost();
        }, [])
    
        

    return (
        <div className="box">
            <header className="major">
				{CategoryName(cat.name)}
		</header>
<ul className="list-group">

{BulletinList(bulletins) }

</ul>
		</div>
    )
};

export default Category;