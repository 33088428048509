import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const signOutUser = async () => {
      try {
        await auth.signOut();
        navigate('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    };

    signOutUser();
  }, []);

  return (
    <div>
      <h1>Signing out...</h1>
    </div>
  );
};

export default SignOut;
