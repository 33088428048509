import axios from 'axios';
import { useEffect } from "react";
import { addBulletinURL } from './Secrets.js';
import { getAuth, getIdToken } from 'firebase/auth';


export const addBulletin = async (title, slug, body, categories, user) => {
  try {
    const auth = getAuth();
    const idToken = await getIdToken(user);

    const response = await axios.post(`${addBulletinURL}`, {
      title,
      slug,
      body,
      categories
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    

    console.log("Bulletin added:", response.data);
  } catch (error) {
    console.error("Error adding bulletin:", error.response.data);
  }
};

const BulletinInput = ({ title, slug, body, categories, user }) => {
  useEffect(() => {
    if (user) {
      const submitBulletin = async () => {
        try {
          await addBulletin(title, slug, body, categories, user);
        } catch (error) {
          console.error("Error in BulletinInput:", error);
        }
      };

      submitBulletin();
    }
  }, [title, slug, body, user]);

  return null;
};

export default BulletinInput;