import React,  { useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import {
  getFirestore,
  addDoc,
  collection,
  query,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { db } from './firebase.js';
import Home from "./components/Home.js";
import About from "./components/About.js";
import Disclaimer from "./components/Disclaimer.js";
import Construction from "./components/Construction.js";
import Contact from "./components/Contact.js";
import Categories from "./components/Categories.js";
import Category from "./components/Category.js";
import Bulletin from "./components/Bulletin.js";
import SignIn from './components/SignIn';
import Testimonials from './components/Testimonials';
import AuthenticatedBulletinForm from './components/AuthenticatedBulletinForm';
import Dashboard from './components/Dashboard';
import EditBulletinForm from './components/EditBulletinForm';
import SignOut from './components/SignOut';
import { firebaseConfig } from './firebase';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';



function CategoryDropdownItems() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "categories"), orderBy("name"));
    const unsubscribe = onSnapshot(q, (snaps) => {
      const newCategories = snaps.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCategories(newCategories);
    });
    return () => unsubscribe();
  }, [db]);

  return (
    <>
      {categories.map((category) => (
        <NavDropdown.Item key={category.id} href={`/categories/${category.slug}`}>
          {category.name}
        </NavDropdown.Item>
      ))}
    </>
  );
}

  
function App() {
  return (
      <Router>
        <div className="body" style={{backgroundColor: '#999999',	backgroundImage: 'url("/images/pattern.9a1bd0aded7d.svg")', backgroundAttachment: 'fixed',
      backgroundSize: 'cover'}}>
           <Navbar bg="dark" variant="dark" expand="lg" className="sticky-top navbar-dark">
  <Container>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ms-auto">
        <Nav.Link className="px-3" href="/">Home</Nav.Link>
        <Nav.Link className="px-3" href="/about">About</Nav.Link>
        <Nav.Link className="px-3" href="/contact">Contact Us</Nav.Link>
        <Nav.Link className="px-3" href="/disclaimer">Disclaimer</Nav.Link>
        <NavDropdown title="Attorney Profile" id="basic-nav-dropdown">
          <NavDropdown.Item href="/testimonials">Testimonials</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Benefits Bulletins" id="basic-nav-dropdown">
          <CategoryDropdownItems />
        </NavDropdown>

        {/* <Nav.Link href="#cta" className="button">Join email list</Nav.Link> */}
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
          <section id="banner" style={{backgroundImage: 'url("/images/overlay.2663ca4a577f.png"), url("/images/banner.305e3479d5f6.jpg")', 
          backgroundAttachment: 'scroll, fixed',
          backgroundColor: '#666',
          backgroundPosition: 'top left, center center',
          backgroundRepeat: 'repeat, no-repeat',
          backgroundSize: 'auto, cover',
          color: '#fff',
          padding: '4em 0 38em 0',
          textAlign: 'center'}}>
            &nbsp;
          </section>

          <section id="main" className="container" style={{marginTop:'-14em'}}>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/categories/:slug" element={<Category/>} />
              <Route path="/categories" element={<Categories/>} />
              <Route path="/contact" element={<Contact/>} />
              <Route path="/disclaimer" element={<Disclaimer/>} />
              <Route path="/bulletins/:slug" element={<Bulletin/>} />
              <Route path="/admin-login" element={<SignIn/>} />
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/add-bulletin" element={<AuthenticatedBulletinForm/>} />
              <Route path="/signout" element={<SignOut />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/edit-bulletin/:id" element={<EditBulletinForm />} />
              <Route path="*" element={<Construction/>} />
            </Routes>
          </section>
          </div>
      </Router>
  );
}

export default App;
