 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
 import { getAuth } from 'firebase/auth';
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries
 // Your web app's Firebase configuration
 export const firebaseConfig = {
    //Add firrbase config here
    apiKey: "AIzaSyC0rNAkYJBIAxqRr6TCqL4Q-qbbeaAilY0",
    authDomain: "benefits-law-group-of-chicago.firebaseapp.com",
    projectId: "benefits-law-group-of-chicago",
    storageBucket: "benefits-law-group-of-chicago.appspot.com",
    messagingSenderId: "15773222986",
    appId: "1:15773222986:web:ad9950e8a3418da7a33eb3",
    measurementId: "G-9EJL59L8ZN"
  };
 // Initialize Firebase
 
 const app = initializeApp(firebaseConfig);
//  const analytics = getAnalytics(app);

 export const auth = getAuth();
 
 // Export firestore database
 // It will be imported into your react app whenever it is needed
 export const db = getFirestore(app);