import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";



const Home = () => {
    const [bulletins, setBulletins] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      });
  
      return () => {
        unsubscribe();
      };
    }, []);


    const fetchBulletins = async () => {
        try {
          const q = query(
            collection(db, 'bulletins'),
            orderBy('createdAt', 'desc'),
            limit(4)
          );
          const querySnap = await getDocs(q);
          const data = querySnap.docs.map((doc) => {
            const bulletin = doc.data();
            const excerpt = bulletin.body.split('\n').slice(0, 1).join('\n');
            return { ...bulletin, excerpt };
          });
          setBulletins(data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching bulletins:', error);
        }
      };

      useEffect(() => {
        fetchBulletins();
      }, []);

  return (
  <>    
				
    <section className="box special">
        <header className="major">
            <h2>Benefits Law Group of Chicago</h2>
            <small>70 W. Madison St., Suite 1500 Chicago, IL 60602 &nbsp; (312) 696-1373 &nbsp;<a href={"mailto:aswilliams@gct.law"}>aswilliams@gct.law</a></small>
        </header>
        <hr/>
        <p className="my-2"><strong>Benefits Law Group of Chicago</strong>, a web site maintained by the Employee Benefit Department of the Chicago, Illinois law firm, <a href={"https://www.gct.law/"}>Golan&nbsp;Christie&nbsp;Taglia&nbsp;LLP</a>, &nbsp;provides information on: </p>
        <div className="d-flex flex-column">
            <div className="px-2 m-1 rounded border bg-light"><Link to={"/categories/labor_and_employment"}>Employment and employment law</Link></div>
            <div className="px-2 m-1 rounded border bg-light">Group health insurance (including <Link to={"/categories/COBRA"}>COBRA</Link> and <Link to={"/categories/HIPAA"}>HIPAA</Link></div>
            <div className="px-2 m-1 rounded border bg-light"><Link to={"/categories/retirement_and_pension_plans/"}>Pension and retirement plans</Link> (including IRAs, <Link to={"/categories/401_k_plans"}>401(k) plans</Link>, thrift plans, <Link to={"/categories/ESOPs"}>ESOPs</Link> and IRS compliance resolution system filings)</div>
            <div className="px-2 m-1 rounded border bg-light"><Link to={"/categories/ERISA-Compliance"}>ERISA and ERISA compliance</Link> (including benefit claims and fiduciary duties)</div>
            <div className="px-2 m-1 rounded border bg-light">Qualified domestic relations orders (QDROs), age discrimination and gender discrimination</div>
            <div className="px-2 m-1 rounded border bg-light">Executive compensation (including deferred compensation and non-compete agreements as well as Section 409A compliance)</div>
            <div className="px-2 m-1 rounded border bg-light">Other state and federal employment matters, including ADA, ADEA and Illinois employment law</div>
        </div>
        <p className="my-2">Benefits Law Group of Chicago is a resource for HR professionals and human resource departments of private companies and not-for-profit organizations such as hospitals and medical centers, universities, associations and charitable organizations.</p>
    </section>

    <section className="box special features">
  <header className="major">
    <h2>Recent Benefits Bulletins</h2>
  </header>
  <div className="features-row row">
  {isLoading ? (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    bulletins.map((bulletin, index) => (
      <div key={index} className="col-lg-6 col-md-12 col-sm-12" >
        <section className='flex-column w-100'>
        <h3 className="bulletin-title" style={{marginBottom: 0}}>
          <Link to={`/bulletins/${bulletin.slug}`}>{bulletin.title}</Link>
        </h3>
        <div className="bulletin-body stretch-bulletin-body">
          <Link to={`/bulletins/${bulletin.slug}`} className="no-blue">
            <div
              style={{
                color: 'rgb(73, 73, 73)',
                fontFamily: 'Arial, Verdana, Helvetica, sans-serif',
                fontSize: '16px',
                whiteSpace: 'pre-wrap',
                textAlign: 'justify',
              }}
              dangerouslySetInnerHTML={{ __html: bulletin.excerpt }}
            >
            </div>
            <div style={{textAlign: "right", color:'#a53917', padding:'0 2em 1em 0' }}>...MORE</div>
          </Link>
        </div>
        </section>
      </div>
    ))
            )}
  </div>
</section>

<section className="box special features"><header className="major"><h2><a href="/testimonials">Testimonials</a></h2></header><div className="features-row"><section><p className="MsoNormal" style={{margin: '0in 0in 0.0001pt', fontSize: 'medium', fontFamily: 'Calibri, sans-serif'}}><span style={{fontFamily: 'Arial', fontSize: '18px'}}>I want to say “THANKS” for all your help, support, patience and expertise through the most challenging time in my life.&nbsp;</span></p><p className="MsoNormal"><span style={{fontFamily: 'Arial', fontSize: '18px'}}>&nbsp;</span></p><p className="MsoNormal"><span style={{fontFamily: 'Arial', fontSize: '18px'}}>It’s great to walk away from this situation feeling we had a great outcome, but even better is to know we achieved this with dignity, class ...</span></p><h3>M. Squiller</h3></section><section><p><span style={{fontFamily: 'Arial', fontSize: '18px'}}>I'd like to take a moment to acknowledge Mr Andrew Williams. I've had the pleasure to work with Mr Williams over roughly a six month period. He took the time to listen to my case against my employer in an ERISA matter involving a Defined Benefit Trust.&nbsp; ...</span></p><h3>E Sanchez</h3></section></div></section>

				<footer id="footer">
					<ul className="icons">
						<li><a href="tel:+13126961373"><FontAwesomeIcon icon={faPhone} /><span className="label"> Call</span></a></li>
						<li><a href="mailto:aswilliams@gct.law"><FontAwesomeIcon icon={faEnvelope} /><span className="label"> Email</span></a></li>
						<li><a href="http://www.linkedin.com/in/andrewswilliams"><FontAwesomeIcon icon={faLinkedin} /><span className="label"> Linked In</span></a></li>
						<li><Link to={"/feed"} className="icon fa-rss"><span className="label">RSS Feed</span></Link></li>
					</ul>
					<ul className="copyright">
						<li>&copy; Benefits Law Group of Chicago. All rights reserved.</li>
					</ul>
          <div style={{marginTop:'1em', fontSize:'.85em'}}>
            {loggedIn ? (
              <>
              <Link to="/signout">Sign Out</Link> &nbsp; | &nbsp; 
              <Link to="/add-bulletin">Add New Bulletin</Link>
              </>
            ) : (
              <Link to="/admin-login">Admin Login</Link>
            )}
            </div>
				</footer>
        </>
        )
};

export default Home;