const Testimonials = () => {
    return (
        <div className="box">
            <section className="box special features">
                <header className="major">
                    <h2>Testimonials</h2>
                </header>
                <div className="features-row row">
                    <div className="col-lg-6 col-md-12 col-sm-12" >
                        
                            <div className="bulletin-body stretch-bulletin-body tanbox">
                                <p><em>I want to say “THANKS” for all your help, support, patience and expertise through the most challenging time in my life. It’s great to walk away from this situation feeling we had a great outcome, but even better is to know we achieved this with dignity, class and professionalism.</em></p>
                                <p style={{textAlign: 'right'}}>M. Squiller</p>
                            </div>
                            <hr/>
                            <div className="bulletin-body stretch-bulletin-body tanbox">
                                <p><em>I'd like to take a moment to acknowledge Mr Andrew Williams. I've had the pleasure to work with Mr Williams over roughly a six month period. He took the time to listen to my case against my employer in an ERISA matter involving a Defined Benefit Trust. </em></p>
                                <p><em>From the very beginning, he listened to my concerns and offered his expertise when answering any questions that I had.  After agreeing to represent me, he worked numerous times with my actuary to get the necessary details of my case. Throughout the entire proces, he was very professional, responsive and extremely knowledgeable regarding the intricate laws surrounding this type of case. Mr. Williams was able to identify numerous violations my employer had committed with this plan and had no reservations about letting my employer know when he was were being unlawful. I truly appreciate the time he took to ensure that I was informed every step of way. </em></p>                                <p><em>That said, I personally feel I would not have been able to come to a victorious outcome had it not been for working together with Mr. Williams.</em></p>
                                <p><em>He is truly an expert in these types of cases and I would absolutely highly recommend Mr. Williams to anyone in need of an excellent ERISA attorney.</em></p>
                                <p style={{textAlign: 'right'}}>E. Sanchez</p>
                            </div>
                            <hr/>
                            
                            <div className="bulletin-body stretch-bulletin-body tanbox">
                                <p><em>Andrew's work product coupled with the supervision of those who were instrumental in helping bring about a most satisfactory settlement … is very impressive. Andrew managed to recover the full obligation on the Promissory Notes but also included an impressive return on an all at risk investment.</em></p>
                                <p style={{textAlign: 'right'}}>Name Withheld, Naples, Florida</p>
                            </div>
                            <hr/>
                            <div className="bulletin-body stretch-bulletin-body tanbox">
                                <p>Even a lawyer may need help when it comes to complicated rules for Section 401(k) retirement plans. We helped one Chicago area lawyer resolve several significant compliance problems for his own retirement plan.  These lingering problems had weighed him down for a number of years.  When we helped him see the light at the end of the tunnel, we were grateful to receive the following:</p>
                                <p><em>"Thank you. I cannot begin to express the peace of mind [your] work has given us. Proof again that a lawyer who represents himself has a fool for a client. Your advice not only clarified our position but saved us money."</em></p>
                                <p style={{textAlign: 'right'}}>Grateful Lawyer</p>
                            </div>
                        
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12" >
                            <div className="bulletin-body stretch-bulletin-body tanbox">
                                <p><em>I worked with Andrew for approximately two years…. In my opinion, Andrew is very knowledgeable and works well with other people…. Andrew was very timely in preparing responses to the other party as well as to me when circumstances required. Andrew has the ability to explain a set of circumstances so the average layman can understand the approach or problem and make a rational decision. During the time I worked with Andrew he did not deviate from his original approach, which indicates his knowledge of the subject and his ability to prepare for the work that is required to obtain the desired results.</em></p>
                                <p><em>I found the services Andrew provided me to be of a very high standard and I was exceptionally pleased with the result he obtained. In my opinion, Andrew is trustworthy, honest, and capable and I would not hesitate using his services in the future should the need arise.</em></p>
                                <p style={{textAlign: 'right'}}>Hazen Weier</p>
                            </div>
                            <hr/>
                            <div className="bulletin-body stretch-bulletin-body tanbox">
                                <p><em>Pension payment issues can disrupt even the best retirement planning.  Particularly disconcerting is a claim that a prior pension benefit has, through mistake, been overpaid.  The retirement plan and the people who supervise it are required to make an effort to recover any overpayment - even if it is their fault!</em></p>
                                <p><em>In one situation like this we were able to resolve the overpayment claim on very reasonable terms that did not upset our client's financial apple cart.  The  client expressed his heartfelt thanks this way:</em></p>
                                <p><em>It has been a long journey to get to this point.  ....  I never would have made it through this successfully without your expertise and guidance. I’m glad I kept every document and made notes on all my conversations with them.  It has been great working with you on this project. I never had to guess what was going on. You notified me on everything, this is something I haven’t experienced before. I finally can have some peace of mind and rest. Thank You for all your time, dedication and patience. My wife ... and I greatly appreciate your dedication to come to a favorable resolution to this. You will always be remembered in this household.</em></p>
                                <p><em>Thank You forever,</em></p>
                                <p style={{textAlign: 'right'}}>H &amp; C</p>
                            </div>
                            <hr/>
                            <div className="bulletin-body stretch-bulletin-body tanbox">
                                <p><em>We were faced with a benefits reduction issue, and rather than try and wade through the bureaucracy and complicated forms by ourselves, we turned to Andrew Williams to guide us and assist in the appeal of the decision. We just received word that the decision was reversed.</em></p>
                                <p><em>We highly recommend Andrew Willams to anyone that requires assistance with issues like this or any benefits related matter.</em></p>
                                <p style={{textAlign: 'right'}}>Art Mazzone</p>
                            </div>
                    </div>
                </div>
            </section>
        </div>
                            
    )
}                    

export default Testimonials;