const About = () => {

    return (
        <section>
            <div className="box">
						<header>
							<h2>Andrew S. Williams</h2>
							<p>Partner - Golan, Christie, Taglia</p>
						</header>
								<img src="https://gct.law/upload/att/261/1261_m.jpg" width="180" height="225" alt="Andrew Williams" style={{float:'left', marginRight:'10px', marginBottom: '10px'}} />
						      <p><strong>Andrew S. Williams</strong> is a Chicago ERISA lawyer. His employee benefit practice encompasses the preparation, qualification, ERISA compliance and termination of retirement plans of all kinds (including pension plans, profit sharing plans, Section 401(k) plans and leveraged employee stock ownership plans, or "ESOPs"); deferred compensation, non-compete and employment agreements as well as Section 409A compliance matters; cafeteria and flexible benefit plans; qualified domestic relations orders (QDROs); group health and other welfare benefit plans (including ERISA subrogation, HIPAA and COBRA coverage matters); representation of clients before the Internal Revenue Service, the U.S. Department of Labor, and the Pension Benefit Guaranty Corporation in EPCRS and VCP submissions as well as private letter ruling requests; ERISA compliance issues including representation in ERISA fiduciary cases; and employment law and employment termination matters, including executive compensation agreements, negotiated severance arrangements, and Illinois employment rights. Mr. Williams also is engaged in a general practice as an attorney and counselor to businesses, human resource professionals, tax-exempt entities, professional organizations and individuals. Mr. Williams has extensive experience as an attorney representing clients in business transactions and acquisitions and benefit issues involved in acquisitions, including reduction in force ("RIF") strategies and documentation. Representative client engagements include:</p>
						      <ul>
						        <li>
						          <p>design, preparation and implementation of qualified retirement plans, deferred compensation arrangements and welfare benefit plans for a wide variety of clients in commerce and the professions, including not-for-profit entities</p>
						        </li>
						        <li>
						          <p>implementation of an Employee Stock Ownership Plan for an S corporation client in order to allow the client to conduct business on a tax-exempt basis</p>
						        </li>
						        <li>
						          <p>correction of retirement plan qualification defects through the Internal Revenue Service (IRS) Employee Plans Compliance Resolution System (EPCRS)</p>
						        </li>
						        <li>
						          <p>private letter ruling request for IRS waiver of the 60-day IRA rollover period pursuant to the Economic Growth and Tax Relief Reconciliation Act of 2001 (EGTRRA)</p>
						        </li>
						        <li>
						          <p>representation of an accounting firm in a U.S. Department of Labor ERISA action seeking recovery of damages on behalf of a qualified retirement plan for breaches of fiduciary duty by one of the firm’s partners</p>
						        </li>
						        <li>
						          <p>representation of real estate developers in a U.S. Department of Labor claim for breach of ERISA fiduciary duties arising from the sale of limited partnership interests to qualified plan investors (see Reich v. McManus, 883 F.Supp. 1144 (N.D. Ill., 1995))</p>
						        </li>
						      </ul>
						      <p>Bar Admissions:&nbsp; Mr. Williams is licensed to practice in the State of Illinois and the United States District Court for the Northern District of Illinois in Chicago , where he is a member of the trial bar.</p>
						      <p>Law School :&nbsp; University of Michigan Law School, J.D., with honors, 1973</p>
						      <p>Undergraduate School :&nbsp; Wabash College , Crawfordsville , Indiana , 1967</p>
						      <p>Undergraduate School Honors/Involvement:&nbsp; magna cum laude; Phi Beta Kappa</p>
						      <p>Bar/Professional Association Involvement:&nbsp; Mr. Williams is a member of the Chicago Bar Association’s Employee Benefits Committee, the Hinsdale Chamber of Commerce, and the Hinsdale, Illinois Plan Commission (2003 - 2006).&nbsp; Mr. Williams speaks on benefit and employment related topics to professional and other groups, and maintains a web site, <a href="/">www.BenefitsLawGroupofChicago.com</a>, which publishes employment and benefit updates, analysis and commentary.</p>
						      <p>Golan Christie Taglia LLP is a mid-size Chicago firm founded in 1993 with a goal of providing affordable legal services in a supportive and collaborative environment. It is “Av” rated by Martindale-Hubbell – its highest rating. Golan Christie Taglia LLP lawyers are seasoned pros who can assist in a wide variety of practice concentrations including corporate law, mergers and acquisitions, real estate and finance, commercial litigation, employment law and litigation, ERISA and employee benefits, estate planning and taxation, bankruptcy and reorganization, intellectual property, and probate/trust administration. Golan Christie Taglia LLP’s offices are located in the heart of Chicago’s loop at 70 West Madison Street, Suite 1500, Chicago, IL 60602.&nbsp;</p>
						      <p>&nbsp;</p>
					</div>
				<div className="box">
						<p><em>"I worked with Andrew for approximately two years…. In my opinion, Andrew is very knowledgeable and works well with other people…. Andrew was very timely in preparing responses to the other party as well as to me when circumstances required. Andrew has the ability to explain a set of circumstances so the average layman can understand the approach or problem and make a rational decision. During the time I worked with Andrew he did not deviate from his original approach, which indicates his knowledge of the subject and his ability to prepare for the work that is required to obtain the desired results.</em></p>
						<p><em>I found the services Andrew provided me to be of a very high standard and I was exceptionally pleased with the result he obtained. In my opinion, Andrew is trustworthy, honest, and capable and I would not hesitate using his services in the future should the need arise."</em></p>
						<p><em>- Hazen Weier</em></p><br/>
								<p><em>"Andrew's work product coupled with the supervision of those who were instrumental in helping bring about a most satisfactory settlement … is very impressive. Andrew managed to recover the full obligation on the Promissory Notes but also included an impressive return on an all at risk investment."&nbsp;</em></p>
								<p><em>- Name Withheld, Naples, Florida</em>&nbsp;</p>
							</div>
        </section>
        )
};

export default About;