import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useState, useEffect } from "react";

const withAuthentication = (Component) => {
  return (props) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setUser(user);
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    }, []);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (user) {
      return <Component {...props} />;
    } else {
      // Redirect to the login page or show a message.
      return <div className="box">Please log in to access this page.</div>;
    }
  };
};
export default withAuthentication;
