const Disclaimer = () => {

    return (
        <section>
            <div className="box">
			<header class="major">
							<h2>Disclaimer</h2>
						</header>
			<hr/>

<p>The materials on this web site have been prepared by Benefits Law Group of Chicago for informational purposes only and are not legal advice. Under the Illinois Rules of Professional Conduct, such content may be considered advertising material. This information is not intended to create, and receipt of it does not create a lawyer-client relationship. Internet subscribers and online readers should not act upon this information without seeking professional counsel. Do not send us information until you speak with one of our lawyers and get authorization to send that information to us. We do not accept a representation which comes to us through our web site if our web site does not comply with the legal or ethical requirements of the state in which the client is located.</p>
			</div>
        </section>
        )
};

export default Disclaimer;