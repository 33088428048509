import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Editor, EditorState, convertFromRaw, convertToRaw, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { updateBulletin, getBulletinById } from "./BulletinFunctions";
import { collection, getDocs, query, where, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import Select from "react-select";

const EditBulletinForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const StyleButton = ({ style, onToggle, active, label }) => {
    const handleMouseDown = (e) => {
      e.preventDefault();
      onToggle(style);
    };
  
    const className = `btn btn-sm ${
      active ? "btn-primary" : "btn-outline-primary"
    } me-1`;
  
    return (
      <button
        type="button"
        className={className}
        onMouseDown={handleMouseDown}
      >
        {label}
      </button>
    );
  };
  
  const blockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    switch (type) {
      case "TANBOX":
        return "tanbox";
      case "GRAYBOX":
        return "graybox";
      case "TITLE":
        return "title";
      case "unordered-list-item":
        return "bulleted-list";
      default:
        return null;
    }
  };
  
  const INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ];
  
  const BLOCK_STYLES = [
    { label: "Tan Box", style: "TANBOX" },
    { label: "Gray Box", style: "GRAYBOX" },
    { label: "Title", style: "TITLE" },
    { label: "Bulleted List", style: "unordered-list-item" },
    // Add more block styles if needed
  ];
  
  const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
  
    return (
      <div className="d-inline-flex">
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
        <button
          type="button"
          className="btn btn-sm btn-outline-primary me-1"
          onMouseDown={(e) => {
            e.preventDefault();
              addLink();
          }}
        >Insert Link</button>
      </div>
    );
  };
  
  const BlockStyleControls = (props) => {
    const { editorState, onToggle } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
  
    return (
      <div className="d-inline-flex">
        {BLOCK_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  const generateSlug = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+/, "") // Remove leading hyphens
      .replace(/-+$/, ""); // Remove trailing hyphens
  };

useEffect(() => {
  const fetchCategories = async () => {
    const categoriesSnap = await getDocs(collection(db, "categories"));
    const fetchedCategories = categoriesSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
    setCategories(fetchedCategories);
  };

  const fetchBulletin = async () => {
    const bulletinData = await getBulletinById(id);
    setTitle(bulletinData.title);
    setSlug(bulletinData.slug);
    setSelectedCategories(bulletinData.categories);
    console.log("Fetched bulletin body:", bulletinData.body); 
    const contentState = stateFromHTML(bulletinData.body);
    console.log("Fetched bulletin body as contentstate:", contentState); 
    setEditorState(EditorState.createWithContent(contentState));
  };

  fetchCategories();
  fetchBulletin();
}, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const htmlBody = stateToHTML(contentState, {
      blockStyleFn: (block) => {
        switch (block.getType()) {
          case "TANBOX":
            return { attributes: { class: "tanbox" } };
          case "GRAYBOX":
            return { attributes: { class: "graybox" } };
          case "TITLE":
            return { attributes: { class: "title" } };
          default:
            return null;
        }
      },
    });
    try {
        console.log("HTML Body:", htmlBody);
        await updateBulletin(id, title, slug, htmlBody, selectedCategories);
        navigate("/categories");
      } catch (error) {
        console.error("Error updating bulletin:", error);
      }
    };
  
  const toggleInlineStyle = (inlineStyle) => {
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    setEditorState(newState);
  };

  const toggleBlockType = (blockType) => {
    const newState = RichUtils.toggleBlockType(editorState, blockType);
    setEditorState(newState);
  };

  const addLink = () => {
    const selectionState = editorState.getSelection();
    if (!selectionState.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selectionState.getStartKey();
      const endKey = selectionState.getEndKey();
      const startOffset = selectionState.getStartOffset();
      const endOffset = selectionState.getEndOffset();
  
      // Get the highlighted text
      const block = contentState.getBlockForKey(startKey);
      const highlightedText = block.getText().slice(startOffset, endOffset);
  
      // Prepopulate the URL input with the highlighted text
      const url = window.prompt("Enter the URL:", highlightedText);
      if (url) {
        const contentStateWithEntity = contentState.createEntity(
          "LINK",
          "MUTABLE",
          { url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity,
        });
  
        setEditorState(
          RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
          )
        );
      }
    }
  };
  
  return (
    <div className="container box">
      <header className="mb-4">
        <h2>Edit Bulletin</h2>
      </header>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title:
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setSlug(generateSlug(e.target.value));
            }}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="slug" className="form-label">
            Slug:
          </label>
          <input
            type="text"
            id="slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="body" className="form-label">
            Body:
          </label>
          <div className="mb-2">
            <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
            />
            <BlockStyleControls
              editorState={editorState}
              onToggle={toggleBlockType}
            />
          </div>
          <div className="border rounded p-2 editor">
            <Editor
              editorState={editorState}
              onChange={(newState) => setEditorState(newState)}
              blockStyleFn={blockStyleFn}
            />
          </div>
        </div>
        <div className="mb-3">
  <label htmlFor="categories" className="form-label">
    Categories:
  </label>
  <Select
    isMulti
    options={categories.map((category) => ({
      value: category.id,
      label: category.name,
    }))}
    value={selectedCategories.map((categoryId) => {
      const category = categories.find((cat) => cat.id === categoryId);
      return { value: categoryId, label: category ? category.name : "" };
    })}
    onChange={(selectedOptions) =>
      setSelectedCategories(
        selectedOptions.map((selectedOption) => selectedOption.value)
      )
    }
    styles={{
      menu: (provided) => ({ ...provided, zIndex: 9999 }),
      menuList: (provided) => ({ ...provided, maxHeight: "200px" }),
    }}
/>
</div>

        <button type="submit" className="btn btn-primary">
      Update
    </button>
  </form>
  
  </div>
);
};

export default EditBulletinForm;