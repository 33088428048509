
const Construction = () => {

    return (
        <section className="box special">
            <img src="/images/construction.jpg" alt="construction" style={{display: 'block', margin: '1em auto'}} />
            <p style={{textAlign: 'center'}}>Our website is undergoing maintenance. Please check back with us soon. Thank you for your patience.</p>
        </section>
        )
};

export default Construction;