import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const Bulletin = () => {
  const { slug } = useParams();
  const [bulletin, setBulletin] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [user, setUser] = useState(null);

useEffect(() => {
  const auth = getAuth();
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  // Clean up the listener when the component unmounts
  return () => {
    unsubscribe();
  };
}, []);


  const fetchCategories = async (categoryIds) => {
    const categoryPromises = categoryIds.map((categoryId) => {
      const categoryRef = doc(db, "categories", categoryId);
      return getDoc(categoryRef);
    });
  
    const categoryDocs = await Promise.all(categoryPromises);
    return categoryDocs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));
  };
  
  const fetchBulletin = async () => {
    const q = query(collection(db, "bulletins"), where("slug", "==", slug));
    const querySnap = await getDocs(q);
  
    querySnap.forEach(async (doc) => {
      const bulletinData = { id: doc.id, ...doc.data() };
  
      // Fetch categories using the categoryIds array in the bulletin data
      const categoryIds = bulletinData.categories || [];
      const categories = await fetchCategories(categoryIds);
  
      // Set the bulletin data and breadcrumbs
      setBulletin(bulletinData);
      setBreadcrumbs(categories);
    });
  };
  

useEffect(() => {
    fetchBulletin();
}, []);



return (
    <div className="box">
      {bulletin ? (
        <>
          <div id="breadcrumbs">
          {bulletin.id ? (
            breadcrumbs.map((breadcrumb) => (
                <div key={breadcrumb.id}><a href={`/categories/`}> BULLETINS </a>&gt; <a href={`/categories/${breadcrumb.slug}`}>{breadcrumb.name}</a></div>
            ))
            ) : (
                <p></p>
            )}

          </div>
          <header className="major">
            <h2>{bulletin.title}</h2>
          </header>
          <div
            className="bulletin-body"
            dangerouslySetInnerHTML={{ __html: bulletin.body }}
          ></div>
          {user && (
            <Link to={`/edit-bulletin/${bulletin.id}`}>
              <button className="btn btn-primary">Edit</button>
            </Link>
)}

        </>
      ) : (
          <div className="d-flex justify-content-center align-items-center">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </div>
      )}
    </div>
  );
  

};

export default Bulletin;
