import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const updateBulletin = async (id, title, slug, body, categories) => {
  const bulletinRef = doc(db, "bulletins", id);

  await updateDoc(bulletinRef, {
    title: title,
    slug: slug,
    body: body,
    categories: categories,
  });
};

export const getBulletinById = async (id) => {
  const bulletinRef = doc(db, "bulletins", id);
  const bulletinSnapshot = await getDoc(bulletinRef);

  if (bulletinSnapshot.exists()) {
    return { id: bulletinSnapshot.id, ...bulletinSnapshot.data() };
  } else {
    console.error("No such bulletin!");
    return null;
  }
};