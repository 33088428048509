
const Contact = () => {

    return (
        <div className="box">
						<header>
							<h2>Andrew S. Williams</h2>
							<p>Partner - Golan, Christie, Taglia</p>
						</header>
						<address><strong>Andrew S. Williams</strong><br/>
					<span class="allcaps">Golan Christie Taglia LLP</span><br/>
					70 West Madison St.<br/>
					Suite 1500<br/>
					Chicago, Illinois 60602<br/>
					<a href="tel:+13126961373" target="_blank" rel="noreferrer">(312) 696-1373</a><br/>
					<a href="mailto:aswilliams@GCT.law" target="_blank" rel="noreferrer">aswilliams@GCT.law</a><br/>
					</address>
					</div>
        )
};

export default Contact;