import { collection, getDocs, query, orderBy, onSnapshot } from "firebase/firestore";
import {db} from '../firebase';
import { useState, useEffect } from 'react';
import _ from 'lodash';


const Categories = () => {
    
	const [cats, setCats] = useState([]);


    const fetchPost = async () => {
        const q = query(collection(db, 'categories'), orderBy('name'));
        onSnapshot(q, snaps => {
            const categories = snaps.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setCats(categories);
        });
    };
    
    
    const CategoryList = (categories) => {
        if (categories.length > 0) {
            return categories.map((cat) =>
                <a href={"/categories/" + cat.slug} key={cat.id}>
                    <li className="list-group-item list-group-item-action">
                        <span className="d-flex justify-content-between align-items-center">
                            <h3>{cat.name}</h3>
                        </span>
                        <br />{cat.description}<br /><br />
                    </li>
                </a>
            );
        } else {
            return (
                <div className="d-flex justify-content-center align-items-center">
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
              );
        }
    };
    

    useEffect(()=>{
        fetchPost();
    }, [])

	

    return (
        <div className="box">
            <header className="major">
                <h2>Benefits Bulletins</h2>
            </header>
            <ul className="list-group" id="categories">
                {CategoryList(cats)}
            </ul>
        </div>
    );
}
export default Categories;